import React from "react";
import { ICONS } from "../../constants";
import TimeDistanceInfo from "../TimeDistanceInfo";

export class CarParkComponent extends React.Component {
    render() {
        return (
            <div className="info-panel">
                <div className="info-segment">
                    <div className="info-segment-icon">
                        <img
                            src="/assets/images/car-white.svg"
                            className="car"
                            alt="car"
                        />
                    </div>
                    <div className="info-segment-text">
                        <div className="title">{this.props.title}</div>
                        <div className="description">{this.props.description}</div>
                    </div>
                </div>
                <TimeDistanceInfo
                    icons={ICONS.walkIcon}
                    distance="10"
                    exitDistance="10"
                />
            </div>
        );
    }
}