import React, { Component } from "react";
import { TramComponent } from "../../components/Tram";
import { TramArrivalComponent } from "../../components/TramArrival";
import UtilityService from "../../services/UtilityService";
import qs from "query-string";
import Hotkeys from "react-hot-keys";

const TRAM_70_TO_CITY = process.env.REACT_APP_TRAM70_TO_CITY_DIRECTION_ID;
const TRAM_70_TO_RICHMOND = process.env.REACT_APP_TRAM70_TO_RICHMOND_ID;
const TRAM_70_7B_STOP_ID = process.env.REACT_APP_TRAM70_7B_STOP_ID;

class MCScreen extends Component {
  state = {
    doors: null,
    alternativeDoors: null,
    staticTimer: null,
    dynamicTimer: null,
    distanceToExit: 0,
    distanceToTram: 0,
    arrowDirection: null
  };

  componentDidMount() {
    let parameters = qs.parse(this.props.location.search);
    let distanceToTram = parseInt(parameters.distanceToTram);
    let distanceToExit = parseInt(parameters.distanceToExit);
    let arrowDirection = parameters.arrowDirection;
    this.setState({
      doors: parameters.doors,
      alternativeDoors: parameters.alternativeDoors,
      distanceToTram: distanceToTram + distanceToExit,
      distanceToExit: distanceToExit,
      arrowDirection: arrowDirection
    });
  }

  sidePanel() {
    var alt = "right";
    var imageSrc = "/assets/images/right-black.svg";

    var arrowDirectionClass = "right-arrow";

    if (this.state.arrowDirection === "left") {
      alt = "left";
      imageSrc = "/assets/images/left-black.svg";
      arrowDirectionClass = "left-arrow";
    }

    return (
      <div className="mc-side-panel">
        <div className="large-arrow-base">
          <img src={imageSrc} className={arrowDirectionClass} alt={alt} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="mc-container">
        <Hotkeys keyName="shift+c" onKeyUp={this.removeService} />
        <Hotkeys keyName="shift+s" onKeyUp={this.shuffleServices} />
        {this.sidePanel()}
        <div className="mc-right">
          <div className="mc-row row-1">
            <TramComponent
              title="Tram 70"
              description="Via Garden Square"
              distanceToTram={this.state.distanceToTram}
              distanceToExit={this.state.distanceToExit}
              isAccessible="true"
            />
          </div>
          <div className="mc-row row-2">
            <TramArrivalComponent
              location={this.props.location}
              directionLabel="To City"
              tram={TRAM_70_TO_CITY}
              tramStopId={TRAM_70_7B_STOP_ID}
            />
          </div>
          <div className="mc-row row-3">
            <TramArrivalComponent
              location={this.props.location}
              directionLabel="To Richmond"
              tram={TRAM_70_TO_RICHMOND}
              tramStopId={TRAM_70_7B_STOP_ID}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MCScreen;
