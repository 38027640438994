export const ICONS = {
  walkAndAccessibilityIcons: [
    { fileName: "walking-black.svg", css: "walking", alt: "walking" },
    {
      fileName: "accessibility.svg",
      css: "accessibility",
      alt: "accessibility"
    }
  ],
  walkTransparentAndAccessibilityIcons: [
    { fileName: "walking_transparent.svg", css: "walking", alt: "walking" },
    {
      fileName: "accessibility.svg",
      css: "accessibility",
      alt: "accessibility"
    }
  ],
  walkIcon: [
    { fileName: "walking_transparent.svg", css: "walking", alt: "walking" }
  ],
  walkBlackIcon: [
    { fileName: "walking-black.svg", css: "walking", alt: "walking" }
  ],
  accesibilitySmallIcon: [
    {
      fileName: "wheelchair.svg",
      css: "accessibility-xs",
      alt: "accessibility"
    }
  ],
  wheelchairIcon: [
    { fileName: "wheelchair.svg", css: "wheelchair", alt: "wheelchair" }
  ]
};

export const INTERVAL_TYPE = {
  STATIC: 0,
  DYNAMIC: 1
};

export const ROUTE_TYPE = {
  TRAIN: 0,
  TRAM: 1
};

export const ARROW_DIRECTION = {
  NONE: null,
  LEFT: "LEFT",
  RIGHT: "RIGHT"
};

// ID's as supplied by PTV - Chronos
export const TRAIN_ID = {
  Alamein: 0,
  CityFlinderStreet: 1,
  Craigieburn: 2,
  Belgrave: 3,
  Cranbourne: 4,
  Frankston: 5,
  GlenWaverley: 6,
  Hurstbridge: 7,
  Lilydale: 8,
  Mernda: 9,
  Pakenham: 10,
  Sandringham: 11,
  StonyPoint: 12,
  Sunbury: 13,
  Upfield: 14,
  Werribee: 15,
  Williamstown: 16,
  ShowgroundsFlemington: 17,
  FlinderStreet: 18
};

export const TRAIN_ATTRIBUTE = {
  0: {
    color: "#152c6b"
  },
  1: {
    color: "#000000"
  },
  2: {
    color: "#152c6b"
  },
  3: {
    color: "#152c6b"
  },
  4: {
    color: "#279fd5"
  },
  5: {
    color: "#028430"
  },
  6: {
    color: "#152c6b"
  },
  7: {
    color: "#be1014"
  },
  8: {
    color: "#152c6b"
  },
  9: {
    color: "#be1014"
  },
  10: {
    color: "#279fd5"
  },
  11: {
    color: "#f178af"
  },
  12: {
    color: "#152c6b"
  },
  13: {
    color: "#152c6b"
  },
  17: {
    color: "#95979a"
  },
  18: {
    color: "#000000"
  },
  9998: {
    color: "#ffffff"
  },
  9999: {
    color: "#000000"
  }
};
