import React from 'react';

const RD_IMAGE_FILENAME = process.env.REACT_APP_RIGHT_DOOR_IMG ? process.env.REACT_APP_RIGHT_DOOR_IMG : 'up.png';

const rdScreen = () => {
    return ( <
        img src = {
            `/assets/images/${RD_IMAGE_FILENAME}`
        }
        className = "direction-screen"
        alt = "right door screen" / >
    );
}

export default rdScreen;