import React from 'react';

const LD_IMAGE_FILENAME = process.env.REACT_APP_LEFT_DOOR_IMG ? process.env.REACT_APP_LEFT_DOOR_IMG : 'up.png';

const ldScreen = () => {
    return ( <
        img src = {
            `/assets/images/${LD_IMAGE_FILENAME}`
        }
        className = "direction-screen"
        alt = "left door screen" / >
    );
}

export default ldScreen;