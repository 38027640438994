import React from "react";
import { PoseGroup } from "react-pose";
import qs from "query-string";
import PollingRequest from "../../models/PollingRequest";
import PollingService from "../../services/PollingService";
import ApiParams from "../../models/ApiParams";
import { INTERVAL_TYPE, ROUTE_TYPE } from "../../constants";
import UtilityService from "../../services/UtilityService";
import TrainBadge from "../TrainBadge";

const STATIC_POLLING_INTERVAL = process.env.REACT_APP_STATIC_POLLING_INTERVAL;
const DYNAMIC_POLLING_INTERVAL = process.env.REACT_APP_DYNAMIC_POLLING_INTERVAL;
const REACT_APP_BASE_DISTANCE_TO_FLINDERS = parseInt(
  process.env.REACT_APP_BASE_DISTANCE_TO_FLINDERS
);
const REACT_APP_BASE_DISTANCE_TO_JOLIMONT = parseInt(
  process.env.REACT_APP_BASE_DISTANCE_TO_JOLIMONT
);

export class TrainArrivalComponent extends React.Component {
  PollingService = new PollingService();

  state = {
    trainTimings: [],
    doors: null,
    alternativeDoors: null,
    staticTimer: null,
    dynamicTimer: null,
    trainStopId: this.props.trainStopId
  };

  componentDidMount() {
    let parameters = qs.parse(this.props.location.search);
    let distanceToExit = parseInt(parameters.distanceToExit);

    this.setState(
      {
        doors: parameters.doors,
        alternativeDoors: parameters.alternativeDoors,
        distanceToFlinders:
          REACT_APP_BASE_DISTANCE_TO_FLINDERS + distanceToExit,
        distanceToJolimont:
          REACT_APP_BASE_DISTANCE_TO_JOLIMONT + distanceToExit,
        distanceToExit: distanceToExit
      },
      () => {
        this.PollingService.init([
          new ApiParams(ROUTE_TYPE.TRAIN, this.state.trainStopId)
        ]);

        this.PollingService.poll(
          new PollingRequest(
            INTERVAL_TYPE.DYNAMIC,
            null,
            this.getBaseTimes().train
          )
        ).then(result => {
          this.setState({
            staticTimer: setInterval(() => {
              this.invokePolling(INTERVAL_TYPE.STATIC);
            }, STATIC_POLLING_INTERVAL),
            dynamicTimer: setInterval(() => {
              this.invokePolling(INTERVAL_TYPE.DYNAMIC);
            }, DYNAMIC_POLLING_INTERVAL)
          });
        });
      }
    );
  }

  getBaseTimes = () => {
    let dateFromUtcTrain = UtilityService.getCurrentTimeStringRelative(
      UtilityService.calculateTimeToArrive(
        this.state.distanceToJolimont,
        this.state.distanceToExit
      )
    );

    return {
      train: dateFromUtcTrain
    };
  };

  invokePolling(intervalType) {
    this.PollingService.poll(
      new PollingRequest(intervalType, null, this.getBaseTimes().train)
    ).then(result => {
      let data = result && result.data && result.data[0] ? result.data[0] : [];

      this.setState({
        trainTimings: data
      });

      this.highlightNextService();
    });
  }

  isNextService = (serviceList, runId) => {
    var run = serviceList.filter(item => item.runId === runId);
    if (run.length === 0) return false;
    return run[0].isNextService === true;
  };

  highlightNextService = () => {
    this.setState(prevState => {
      var j = [...prevState.trainTimings];

      j.forEach(item => (item.isNextService = false));

      j.filter(item =>
        item.estimatedScheduledTime.isSame(j[0].estimatedScheduledTime)
      ).forEach(item => (item.isNextService = true));

      return {
        trainTimings: j
      };
    });
  };

  removeService = () => {
    clearInterval(this.state.staticTimer);
    clearInterval(this.state.dynamicTimer);

    this.setState(prevState => ({
      trainTimings: prevState.trainTimings.slice(1)
    }));
  };

  shuffleServices = () => {
    this.setState(prevState => ({
      trainTimings: UtilityService.shuffle(prevState.trainTimings)
    }));

    this.setState(prevState => {
      var j = [...prevState.trainTimings];

      j[0].lowFloor = true;

      return {
        trainTimings: j
      };
    });
  };

  getTrainBadger() {
    return this.state.trainTimings
      ? this.state.trainTimings.map((timing, id) => {
          let arrivalTime = UtilityService.relativeTimeTo(
            timing.estimatedScheduledTime
          );
          let firstOfService = this.isNextService(
            this.state.trainTimings,
            timing.runId
          );
          let outerCss = firstOfService ? "bg-white" : "";
          return (
            <TrainBadge
              key={`j-badge-${timing.runId}`}
              hasBorder={!firstOfService}
              activeOuterCss={outerCss}
              directionId={timing.directionId}
            >
              <div className="main">{timing.directionName}</div>
              <div className="caption bg-white">
                <span className="time">{arrivalTime}</span>
                <span className="platform">
                  {timing.platformNumber
                    ? " | Platform " + timing.platformNumber
                    : null}
                </span>
              </div>
            </TrainBadge>
          );
        })
      : [];
  }

  render() {
    var badges = this.getTrainBadger();

    return (
      <div className="train-arrival wrap-overflow">
        <PoseGroup animateOnMount={false} onRest={this.highlightNextService}>
          {badges}
        </PoseGroup>
      </div>
    );
  }
}
