import React, { Component } from "react";
import TimeDistanceInfo from "../../components/TimeDistanceInfo";
import TrainBadge from "../../components/TrainBadge";
import PollingService from "../../services/PollingService";
import { ICONS, INTERVAL_TYPE, ROUTE_TYPE } from "../../constants";
import ApiParams from "../../models/ApiParams";
import PollingRequest from "../../models/PollingRequest";
import UtilityService from "../../services/UtilityService";
import qs from "query-string";
import { PoseGroup } from "react-pose";
import Hotkeys from "react-hot-keys";

const STATIC_POLLING_INTERVAL = process.env.REACT_APP_STATIC_POLLING_INTERVAL;
const DYNAMIC_POLLING_INTERVAL = process.env.REACT_APP_DYNAMIC_POLLING_INTERVAL;
const RICHMOND_STOP_ID = process.env.REACT_APP_RICHMOND_STOP_ID;
const REACT_APP_BASE_DISTANCE_TO_RICHMOND = parseInt(
  process.env.REACT_APP_BASE_DISTANCE_TO_RICHMOND
);
const REACT_APP_BASE_DISTANCE_TO_CARPARK = parseInt(
  process.env.REACT_APP_BASE_DISTANCE_TO_CARPARK
);
const REACT_APP_BASE_DISTANCE_TO_TAXIRANK = parseInt(
  process.env.REACT_APP_BASE_DISTANCE_TO_TAXIRANK
);

class NBPScreen extends Component {
  PollingService = new PollingService();
  state = {
    RichmondTrainTimings: null
  };

  componentDidMount() {
    let parameters = qs.parse(this.props.location.search);
    let distanceToExit = parseInt(parameters.distanceToExit);
    this.setState(
      {
        doors: parameters.doors,
        alternativeDoors: parameters.alternativeDoors,
        distanceToRichmond:
          REACT_APP_BASE_DISTANCE_TO_RICHMOND + distanceToExit,
        distanceToCarPark: REACT_APP_BASE_DISTANCE_TO_CARPARK + distanceToExit,
        distanceToTaxiRank:
          REACT_APP_BASE_DISTANCE_TO_TAXIRANK + distanceToExit,
        distanceToExit: distanceToExit
      },
      () => {
        this.PollingService.init([
          new ApiParams(ROUTE_TYPE.TRAIN, RICHMOND_STOP_ID)
        ]);
        this.PollingService.poll(
          new PollingRequest(
            INTERVAL_TYPE.DYNAMIC,
            null,
            this.getBaseTimes().train
          )
        ).then(result => {
          setInterval(() => {
            this.invokePolling(INTERVAL_TYPE.STATIC);
          }, STATIC_POLLING_INTERVAL);
          setInterval(() => {
            this.invokePolling(INTERVAL_TYPE.DYNAMIC);
          }, DYNAMIC_POLLING_INTERVAL);
        });
      }
    );
  }

  getBaseTimes = () => {
    let dateFromUtcTrain = UtilityService.getCurrentTimeStringRelative(
      UtilityService.calculateTimeToArrive(
        this.state.distanceToRichmond,
        this.state.distanceToExit
      )
    );

    return {
      train: dateFromUtcTrain
    };
  };

  isNextService = (serviceList, runId) => {
    var run = serviceList.filter(item => item.runId === runId);
    if (run.length === 0) return false;
    return run[0].isNextService === true;
  };

  highlightNextService = () => {
    this.setState(prevState => {
      var r = [...prevState.RichmondTrainTimings];

      r.forEach(item => (item.isNextService = false));

      r.filter(item =>
        item.estimatedScheduledTime.isSame(r[0].estimatedScheduledTime)
      ).forEach(item => (item.isNextService = true));

      return { RichmondTrainTimings: r };
    });
  };

  shuffleServices = () => {
    this.setState(prevState => ({
      RichmondTrainTimings: UtilityService.shuffle(
        prevState.RichmondTrainTimings
      )
    }));

    this.setState(prevState => {
      var r = [...prevState.RichmondTrainTimings];

      r[0].lowFloor = true;

      return { RichmondTrainTimings: r };
    });
  };

  removeService = () => {
    clearInterval(this.state.staticTimer);
    clearInterval(this.state.dynamicTimer);

    this.setState(prevState => ({
      RichmondTrainTimings: prevState.RichmondTrainTimings.slice(1)
    }));
  };

  invokePolling(intervalType) {
    this.PollingService.poll(
      new PollingRequest(intervalType, null, this.getBaseTimes().train)
    ).then(result => {
      let richmondTrain =
        result && result.data && result.data[0] ? result.data[0] : [];

      this.setState({
        RichmondTrainTimings: richmondTrain
      });

      this.highlightNextService();
    });
  }

  render() {
    let richmondArrivalBadges = this.state.RichmondTrainTimings
      ? this.state.RichmondTrainTimings.map((timing, id) => {
          let arrivalTime = UtilityService.relativeTimeTo(
            timing.estimatedScheduledTime
          );
          let firstOfService = this.isNextService(
            this.state.RichmondTrainTimings,
            timing.runId
          );
          let outerCss = firstOfService ? "bg-white" : "";
          let innerCss = firstOfService ? "bg-blue" : "";
          return (
            <TrainBadge
              key={`j-badge-${timing.runId}`}
              hasBorder={!firstOfService}
              hasIcons={false}
              icons={
                timing.isApproaching
                  ? ICONS.accesibilitySmallIcon
                  : ICONS.wheelchairIcon
              }
              activeOuterCss={outerCss}
              activeInnerCss={innerCss}
              directionId={timing.directionId}
            >
              <div className="main">{timing.directionName}</div>
              <div className="caption dark-grey">
                <span className="time">{arrivalTime}</span>
                <span className="platform">
                  {timing.platformNumber
                    ? " | Platform " + timing.platformNumber
                    : null}
                </span>
              </div>
            </TrainBadge>
          );
        })
      : null;

    return (
      <div className="nbp-container">
        <Hotkeys keyName="shift+c" onKeyUp={this.removeService} />
        <Hotkeys keyName="shift+s" onKeyUp={this.shuffleServices} />
        <div className="nbp-side-panel">
          <div className="large-arrow-base">
            <img src="/assets/images/up.png" className="up" alt="up" />
          </div>
        </div>
        <div className="nbp-right">
          <div className="nbp-row row-1 header-row">
            <div className="header-segment">
              <div className="header">
                <span className="header-name">Olympic Blvd</span>
                <span className="header-exit">Exit</span>
              </div>
              <div className="sub-header">
                <span>
                  At {UtilityService.singularOrPlural(this.state.doors, "door")}{" "}
                  {this.state.doors}
                </span>
              </div>
            </div>
            <TimeDistanceInfo
              icons={ICONS.walkTransparentAndAccessibilityIcons}
              distance={this.state.distanceToExit}
              exitDistance={this.state.distanceToExit}
            />
          </div>
          <div className="nbp-row row-2">
            <div className="info-segment">
              <div className="info-segment-icon">
                <img
                  src="/assets/images/car-white.svg"
                  className="car"
                  alt="car"
                />
              </div>
              <div className="info-segment-text">
                <div className="title">Eastern Car Park</div>
                <div className="description">
                  Turn left via Olympic Boulevard
                </div>
              </div>
            </div>
            <TimeDistanceInfo
              icons={ICONS.walkTransparentAndAccessibilityIcons}
              distance={this.state.distanceToCarPark}
              exitDistance={this.state.distanceToExit}
            />
          </div>
          <div className="nbp-row row-3">
            <div className="info-panel">
              <div className="info-segment">
                <div className="info-segment-icon">
                  <img
                    src="/assets/images/train.svg"
                    className="train"
                    alt="train"
                  />
                </div>
                <div className="info-segment-text">
                  <div className="title">Richmond Station</div>
                  <div className="description">
                    Turn left via Olympic Boulevard
                  </div>
                </div>
              </div>
              <TimeDistanceInfo
                icons={ICONS.walkTransparentAndAccessibilityIcons}
                distance={this.state.distanceToRichmond}
                exitDistance={this.state.distanceToExit}
              />
            </div>
            <div className="train-arrival wrap-overflow">
              <PoseGroup
                animateOnMount={false}
                onRest={this.highlightNextService}
              >
                {richmondArrivalBadges}
              </PoseGroup>
            </div>
          </div>
        </div>

        <div className="footer nbp-footer">
          <span className="turn-back-icon">
            <img
              src="/assets/images/turn-back-arrow.png"
              alt="u-turn"
              className="turn-back-arrow"
            />
          </span>
          <span className="direction-block">
            <div className="direction">Turn back for</div>
            <div className="direction-destination">
              <span>MCG/City </span>
              <span>Exit</span>
            </div>
            <div className="direction-exit">
              At{" "}
              {UtilityService.singularOrPlural(
                this.state.alternativeDoors,
                "door"
              )}{" "}
              {this.state.alternativeDoors}
            </div>
          </span>
          <span className="icon-with-text">
            <div className="icon-set">
              <img src="/assets/images/tram.svg" className="tram" alt="tram" />
              <span className="icon-text">Tram 70</span>
            </div>
          </span>
          <span className="icon-with-text">
            <div className="icon-set">
              <img
                src="/assets/images/train.svg"
                className="train"
                alt="train"
              />
              <span className="icon-text">Flinders Street Station</span>
            </div>
          </span>
          <span className="icon-with-text">
            <div className="icon-set">
              <img
                src="/assets/images/train.svg"
                className="train"
                alt="train"
              />
              <span className="icon-text">Jolimont Station</span>
            </div>
          </span>
        </div>
      </div>
    );
  }
}

export default NBPScreen;
