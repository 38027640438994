import React from "react";
import { ICONS } from "../../constants";
import TimeDistanceInfo from "../TimeDistanceInfo";

export class TrainComponent extends React.Component {
    render() {
        const infoDisplay = () => {
            if ((this.props.isAccessible) && (this.props.isAccessible === "true")) {
                return (
                    <TimeDistanceInfo
                        icons={ICONS.walkTransparentAndAccessibilityIcons}
                        distance={this.props.distanceToTrain}
                        exitDistance={this.props.distanceToExit}
                    />
                );
            } else {
                return (
                    <TimeDistanceInfo
                        icons={ICONS.walkIcon}
                        distance={this.props.distanceToTrain}
                        exitDistance={this.props.distanceToExit}
                    />
                );
            }
        }

        return (
            <div className="info-panel">
                <div className="info-segment">
                    <div className="info-segment-icon">
                        <img
                            src="/assets/images/train.svg"
                            className="train"
                            alt="Train"
                        />
                    </div>
                    <div className="info-segment-text">
                        <div className="title">{this.props.title}</div>
                        <div className="description">{this.props.description}</div>
                    </div>
                </div>
                {infoDisplay()}
            </div>
        )
    }
}