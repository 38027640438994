import React from "react";
import posed from "react-pose";
import { TRAIN_ATTRIBUTE } from "../../constants/index";

const badge = React.forwardRef((props, ref) => {
  const directionId = props.directionId;

  var lineColour = TRAIN_ATTRIBUTE[9999].color;
  if (TRAIN_ATTRIBUTE[directionId] !== undefined) {
    lineColour = TRAIN_ATTRIBUTE[directionId].color;
  } else {
    console.log("Undefined", directionId);
  }

  // If line colour ir black - Flinders
  // And and badge is active
  // The switch color to white
  if (lineColour === "#000000" && props.activeOuterCss) {
    lineColour = TRAIN_ATTRIBUTE[9998].color;
  }

  let icons = props.icons
    ? props.icons.map((icon, id) => {
        return (
          <img
            key={id}
            src={`/assets/images/${icon.fileName}`}
            className={icon.css}
            alt={icon.alt}
          />
        );
      })
    : [];
  let rightTab =
    props.hasIcons && icons.length > 0 ? (
      <div
        className={`right-tab ${props.iconCss ? props.iconCss : ""} ${
          props.activeInnerCss ? props.activeInnerCss : ""
        }`}
      >
        {icons}
      </div>
    ) : null;

  let opacity = props.addOpacity ? "opacity" : "";
  let badgeBottom = props.activeOuterCss
    ? "badge-bottom-active"
    : "badge-bottom";
  return (
    <div className="badge-train" ref={ref}>
      <div
        className={`badge-top ${opacity} ${
          props.activeOuterCss ? props.activeOuterCss : ""
        }`}
        style={props.style}
      >
        <div className={`left-tab ${props.textCss ? props.textCss : ""}`}>
          {props.children}
        </div>
      </div>
      <div
        className={`${opacity} ${badgeBottom}`}
        style={{ backgroundColor: lineColour }}
      />
    </div>
  );
});

//         {rightTab}

const animatedBadge = posed(badge)({
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 400 }
  },
  exit: {
    opacity: 0,
    y: "100%",
    transition: { duration: 200 }
  },
  flip: {
    scale: 1,
    transition: {
      duration: 300
    }
  }
});

export default animatedBadge;
