import React from "react";
import { ICONS, ARROW_DIRECTION } from "../../constants";
import TimeDistanceInfo from "../TimeDistanceInfo";

export class TaxiRankComponent extends React.Component {
    directionArrow(arrowDirection) {
        switch (arrowDirection) {
            case ARROW_DIRECTION.RIGHT:
                return <img
                    src='/assets/images/right-black.svg'
                    className="direction-label"
                    alt="Up"
                />
            default:
                return null;
        }
    }

    render() {
        return (
            <div style={{display:"inline-flex", width:"100%"}}>
                <div className="taxi-rank info-panel" style={{width:"100%"}}>
                    <div className="info-segment" >
                        <div className="info-segment-icon">
                            <img
                                src="/assets/images/taxi.svg"
                                className="taxi"
                                alt="Taxi"
                            />
                        </div>
                        <div className="info-segment-text">
                            <div className="title">{this.props.title}</div>
                            <div className="description">{this.props.description}</div>
                        </div>
                    </div>
                    <TimeDistanceInfo
                        icons={ICONS.walkAndAccessibilityIcons}
                        distance={this.props.distance}
                        exitDistance="10"
                    />
                </div>
                <div style={{float:'right'}}>
                    {this.directionArrow(ARROW_DIRECTION.RIGHT)}
                </div>
            </div>
        );
    }
}

//{this.directionArrow(ARROW_DIRECTION.RIGHT)}