import React, { Component } from 'react';
import './styles/css/index.css';
import MCGScreen from './screens/MCGScreen'; // Melbourne Cricket Group
import NBPScreen from './screens/NBPScreen'; // North Olymic Boulevard

import LDScreen from './screens/LDScreen';
import RDScreen from './screens/RDScreen';


import MAScreen from './screens/MAScreen';    // Melbourne Arena Screen 
import MCScreen from './screens/MCScreen';    // Margret Court Screen

import ConfigurationScreen from './screens/ConfigurationScreen';
import { Route, Switch } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <div className="app">
        <Switch>  
          <Route exact path="/MC" component={MCScreen} />
          <Route exact path="/MA" component={MAScreen} />

          <Route exact path="/LD" component={LDScreen} />     
          <Route exact path="/RD" component={RDScreen} />
          <Route exact path="/nbp/:id?" component={NBPScreen} />
          <Route path="/mcg/:id?" component={MCGScreen} />
          <Route path="*" component={ConfigurationScreen} />


        </Switch>
      </div>
    );
  }
}

export default App;
