import React, { Component } from "react";
import qs from "query-string";
import Hotkeys from "react-hot-keys";
import { TramComponent } from "../../components/Tram";
import { TramArrivalComponent } from "../../components/TramArrival";
import { TrainComponent } from "../../components/Train";
import { TrainArrivalComponent } from "../../components/TrainArrival";
import { CarParkComponent } from "../../components/CarPark";
import { RideShareComponent } from "../../components/RideShare";
import { TaxiRankComponent } from "../../components/TaxiRank";

const RICHMOND_STOP_ID = process.env.REACT_APP_RICHMOND_STOP_ID;
const TRAM_70_TO_CITY = process.env.REACT_APP_TRAM70_TO_CITY_DIRECTION_ID;
const TRAM_70_TO_RICHMOND = process.env.REACT_APP_TRAM70_TO_RICHMOND_ID;
const TRAM_70_7C_STOP_ID = process.env.REACT_APP_TRAM70_7C_STOP_ID;
const REACT_APP_BASE_DISTANCE_TO_FLINDERS = parseInt(
  process.env.REACT_APP_BASE_DISTANCE_TO_FLINDERS
);
const REACT_APP_BASE_DISTANCE_TO_JOLIMONT = parseInt(
  process.env.REACT_APP_BASE_DISTANCE_TO_JOLIMONT
);

class MAScreen extends Component {
  state = {
    doors: null,
    alternativeDoors: null
  };

  componentDidMount() {
    let parameters = qs.parse(this.props.location.search);
    let distanceToTram = parseInt(parameters.distanceToTram);
    let distanceToExit = parseInt(parameters.distanceToExit);
    this.setState({
      doors: parameters.doors,
      alternativeDoors: parameters.alternativeDoors,
      distanceToFlinders: REACT_APP_BASE_DISTANCE_TO_FLINDERS + distanceToExit,
      distanceToJolimont: REACT_APP_BASE_DISTANCE_TO_JOLIMONT + distanceToExit,
      distanceToTram: distanceToTram + distanceToExit,
      distanceToExit: distanceToExit
    });
  }

  headerArrow() {
    return (
      <div style={{ float: "left" }}>
        <img
          src="/assets/images/left-white.svg"
          className="direction-label"
          alt="Left"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="ma-container">
        <div className="row-top header-row">
          <div className="header-segment" style={{ display: "inline" }}>
            {this.headerArrow()}
            <div className="header">
              <span className="header-name" style={{ float: "left" }}>
                Trams, Trains & Car Park
              </span>
            </div>
          </div>
        </div>
        <div className="ma-row">
          <div className="column-left">
            <div className="row-1">
              <TramComponent
                title="Tram 70"
                description="Via Eastern Plaza"
                distanceToTram={this.state.distanceToTram}
                distanceToExit={this.state.distanceToExit}
                isAccessible="true"
              />
            </div>
            <div className="row-2">
              <TramArrivalComponent
                location={this.props.location}
                directionLabel="To City"
                tram={TRAM_70_TO_CITY}
                tramStopId={TRAM_70_7C_STOP_ID}
              />
              <TramArrivalComponent
                location={this.props.location}
                directionLabel="To Richmond"
                tram={TRAM_70_TO_RICHMOND}
                tramStopId={TRAM_70_7C_STOP_ID}
              />
            </div>
          </div>
          <div className="column-right">
            <div className="row-1">
              <TrainComponent
                title="Richmond Station"
                description="Via Olympic Blvd"
                isAccessible="true"
                distanceToTrain="1000"
                distanceToExit="100"
              />
            </div>
            <div className="row-2">
              <TrainArrivalComponent
                location={this.props.location}
                trainStopId={RICHMOND_STOP_ID}
              />
            </div>
            <div className="row-3">
              <CarParkComponent
                title="Eastern Car Park"
                description="Via Olympic Blvd"
                distance={this.props.distanceToTrain}
                exitDistance={this.props.distanceToExit}
              />
            </div>
          </div>
        </div>

        <div className="footer ma-footer">
          <div className="column-left">
            <RideShareComponent title="Rideshare" distance="20" />
          </div>
          <div className="column-right">
            <TaxiRankComponent title="Taxi Rank" distance="80" />
          </div>
        </div>
      </div>
    );
  }
}

export default MAScreen;
