import React from "react";
import { PoseGroup } from "react-pose";
import qs from "query-string";
import PollingService from "../../services/PollingService";
import ApiParams from "../../models/ApiParams";
import {
  ICONS,
  INTERVAL_TYPE,
  ROUTE_TYPE,
  ARROW_DIRECTION
} from "../../constants";
import PollingRequest from "../../models/PollingRequest";
import UtilityService from "../../services/UtilityService";
import TramBadge from "../TramBadge";

const STATIC_POLLING_INTERVAL = process.env.REACT_APP_STATIC_POLLING_INTERVAL;
const DYNAMIC_POLLING_INTERVAL = process.env.REACT_APP_DYNAMIC_POLLING_INTERVAL;
const REACT_APP_BASE_DISTANCE_TO_FLINDERS = parseInt(
  process.env.REACT_APP_BASE_DISTANCE_TO_FLINDERS
);
const REACT_APP_BASE_DISTANCE_TO_TRAM_70 = parseInt(
  process.env.REACT_APP_BASE_DISTANCE_TO_TRAM_70
);

export class TramArrivalComponent extends React.Component {
  PollingService = new PollingService();

  state = {
    tramTimings: [],
    doors: null,
    alternativeDoors: null,
    staticTimer: null,
    dynamicTimer: null
  };

  componentDidMount() {
    let parameters = qs.parse(this.props.location.search);
    let distanceToExit = parseInt(parameters.distanceToExit);

    this.setState(
      {
        doors: parameters.doors,
        alternativeDoors: parameters.alternativeDoors,
        distanceToFlinders:
          REACT_APP_BASE_DISTANCE_TO_FLINDERS + distanceToExit,
        distanceToTram70: REACT_APP_BASE_DISTANCE_TO_TRAM_70 + distanceToExit,
        distanceToExit: distanceToExit
      },
      () => {
        this.PollingService.init([
          new ApiParams(ROUTE_TYPE.TRAM, this.props.tramStopId)
        ]);

        this.PollingService.poll(
          new PollingRequest(
            INTERVAL_TYPE.DYNAMIC,
            this.getBaseTimes().tram,
            null
          )
        ).then(result => {
          this.setState({
            staticTimer: setInterval(() => {
              this.invokePolling(INTERVAL_TYPE.STATIC);
            }, STATIC_POLLING_INTERVAL),
            dynamicTimer: setInterval(() => {
              this.invokePolling(INTERVAL_TYPE.DYNAMIC);
            }, DYNAMIC_POLLING_INTERVAL)
          });
        });
      }
    );
  }

  getBaseTimes = () => {
    let dateFromUtcTram = UtilityService.getCurrentTimeStringRelative(
      UtilityService.calculateTimeToArrive(
        this.state.distanceToTram70,
        this.state.distanceToExit
      )
    );

    return {
      tram: dateFromUtcTram
    };
  };

  invokePolling(intervalType) {
    var directionId = this.props.tram;

    this.PollingService.poll(
      new PollingRequest(intervalType, this.getBaseTimes().tram, null)
    ).then(result => {
      let tramTimings =
        result && result.data && result.data[directionId]
          ? result.data[directionId]
          : [];

      this.setState({
        tramTimings: tramTimings
      });

      this.highlightNextService();
    });
  }

  isNextService = (serviceList, runId) => {
    var run = serviceList.filter(item => item.runId === runId);
    if (run.length === 0) return false;
    return run[0].isNextService === true;
  };

  highlightNextService = () => {
    this.setState(prevState => {
      var tc = [...prevState.tramTimings];

      tc.forEach(item => (item.isNextService = false));

      tc.filter(item =>
        item.estimatedScheduledTime.isSame(tc[0].estimatedScheduledTime)
      ).forEach(item => (item.isNextService = true));

      return {
        tramTimings: tc
      };
    });
  };

  removeService = () => {
    clearInterval(this.state.staticTimer);
    clearInterval(this.state.dynamicTimer);

    this.setState(prevState => ({
      tramTimings: prevState.tramTimings.slice(1)
    }));
  };

  shuffleServices = () => {
    this.setState(prevState => ({
      tramTimings: UtilityService.shuffle(prevState.tramTimings)
    }));

    this.setState(prevState => {
      var tc = [...prevState.tramTimings];

      tc[0].lowFloor = true;

      return {
        tramTimings: tc
      };
    });
  };

  getTramBadges() {
    return this.state.tramTimings
      ? this.state.tramTimings.map((timing, index) => {
          let arrivalTime = UtilityService.relativeTimeTo(
            timing.estimatedScheduledTime
          );
          let outerCss = this.isNextService(
            this.state.tramTimings,
            timing.runId
          )
            ? "bg-white"
            : "";
          return (
            <TramBadge
              key={`tc-badge-${timing.runId}`}
              icons={ICONS.accesibilitySmallIcon}
              hasIcons={timing.lowFloor}
              hasBorder={
                !this.isNextService(this.state.tramTimings, timing.runId)
              }
              activeOuterCss={outerCss}
              iconCss={timing.lowFloor && timing.isNextService ? "bg-blue" : ""}
            >
              <div className="caption bg-white">{arrivalTime}</div>
            </TramBadge>
          );
        })
      : null;
  }

  directionArrow(arrowDirection) {
    switch (arrowDirection) {
      case ARROW_DIRECTION.LEFT:
        return (
          <img
            src="/assets/images/left.svg"
            className="direction-label"
            alt="Left"
          />
        );
      case ARROW_DIRECTION.RIGHT:
        return (
          <img
            src="/assets/images/right.svg"
            className="direction-label"
            alt="Right"
          />
        );
      default:
        return null;
    }
  }

  render() {
    var badges = [];
    badges = this.getTramBadges();

    return (
      <div className="tram-arrival">
        <div className="info-header">
          {this.directionArrow(this.props.direction)}
          <span className="direction-label">{this.props.directionLabel}</span>
        </div>
        <div className="tram-arrival-info wrap-overflow">
          <PoseGroup animateOnMount={false} onRest={this.highlightNextService}>
            {badges}
          </PoseGroup>
        </div>
      </div>
    );
  }
}
