import moment from "moment";

export default class Service {
	constructor(
		runId,
		routeType,
		directionId,
		directionName,
		lowFloor,
		airConditioned,
		platformNumber,
		estimatedScheduledTime,
		timeDifference,
		isApproaching,
		isRealTime
	) {
		this.runId = runId;
		this.routeType = routeType;
		this.directionId = directionId;
		this.directionName = directionName;
		this.lowFloor = lowFloor;
		this.airConditioned = airConditioned;
		this.platformNumber = platformNumber;

		var rounded = moment(estimatedScheduledTime).startOf("minute");

		this.estimatedScheduledTime = rounded;
		this.disruption = "";
		this.cancelled = "";
		this.timeDifference = timeDifference ? timeDifference : 0;
		this.isApproaching = isApproaching;
		this.isRealTime = isRealTime;

		this.isNextService = false;
	}
}
