/*
    This service handles the http call to the backend server and returned data 
    to the polling service for further processing. This includes error handling
    from the API call so it won't break the other service. 
*/
import Response from '../models/Response';

const API_URL = process.env.REACT_APP_API_URL;
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
};

const METHOD = {
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
    PUT: "PUT"
};



const get = (url, body) => {
    let payload = {
        method: METHOD.GET,
        headers: headers
    };
    if (body) {
        payload.body = JSON.stringify(body);
    }
    return fetch(url, payload).then(response => {
        if (response) {
            return response.json().then(data => {
                return new Response(true, data, response.headers);
            });
        }

        return Promise.resolve(new Response(false));
    });
};

class HttpService {
    getData = (params) => {
        if (!params || !params.stop) {
            console.log('PARAMETER ERROR HTTP SERVICE', params);
            return Promise.resolve(new Response(false));
        }

        let url = `${API_URL}${API_ENDPOINT}?mode=${params.mode}&stop=${params.stop}&date_utc=${params.date_utc}`;
        //let url = 'http://localhost:3000/json/mock.json'; 
        return get(url);
    };
}

export default new HttpService();