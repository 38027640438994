import React, { Component } from "react";
import Select from "react-select";

const options = [
	{ value: "/mc" , label: "Margaret Court", doors: "1-7", alternativeDoors: "8-15", arrowDirection:"left" },
	{ value: "/mcg", label: "MCG Exit", doors: "14-15", alternativeDoors: "1-2" },
	{ value: "/ma" , label: "Melbourne Arena" },
	{ value: "/nbp", label: "Olympic Blvd", doors: "1-2", alternativeDoors: "14-15" },
];

const arrowDirectionOptions = [
	{ value: "left", label: "Left"},
	{ value: "right", label: "Right"}
]

const customStyles = {
	option: (provided, state) => ({
		...provided,
		background: "white",
		border: "solid 1px #fff",
		color: state.isSelected ? "blue" : "black",
		padding: 20
	}),
	control: (provided, state) => ({
		...provided,
		background: "white",
		border: "solid 1px #fff"
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		return { ...provided, opacity, transition };
	}
};

class ConfigurationScreen extends Component {
	constructor(props) {
		super(props);

		this.state = {
			screen: "",
			doors: "",
			arrowDirection: ""
		};
	}

	handleScreenChange = selectedOption => {
		this.setState({ 
			screen: selectedOption,
			doors: selectedOption.doors,
			alternativeDoors: selectedOption.alternativeDoors
		});
	};

	handleArrowChange = selectedOption => {
		this.setState({ 			
			arrowDirection: selectedOption
		});
	};

	handleInputChange = (event) => {
	  const target = event.target;
	  const value = target.type === 'checkbox' ? target.checked : target.value;
	  const name = target.name;
  
	  this.setState({
		[name]: value
	  });
	}


	render() {
		return (
			<div className="configuration">
				<h1>Configuration</h1>

				<form action={this.state.screen.value} method="get">
					<div className="form-control">
						<label>
							Screen Type
							<Select
								styles={customStyles}
								value={this.state.screen}
								onChange={this.handleScreenChange}
								options={options}
								className="select"
								required
							/>
						</label>
					</div>
					<div className="form-control">
						<label>
							Doors{" "}
							<input
								type="text"
								name="doors"
								placeholder="Exit Doors"
								value={this.state.doors}
								onChange={this.handleInputChange}
							/>
						</label>
					</div>
					<div className="form-control">
						<label>
							Alternative Doors{" "}
							<input
								type="text"
								name="alternativeDoors"
								placeholder="Alternative Exit Doors"
								value={this.state.alternativeDoors}
								onChange={this.handleInputChange}
							/>
						</label>
					</div>
					<div className="form-control">
						<label>
							Distance to Exit{" "}
							<input
								type="number"
								name="distanceToExit"
								placeholder="Distance to Exit"
								required
								min="0"
								max="500"
								step="1"
							/>
						</label>
					</div>
					<div className="form-control">
						<label>
							Distance to Tram (Margaret Court & Melbourne Arena) {" "}
							<input
								type="number"
								name="distanceToTram"
								placeholder="Distance to Tram"
								min="0"
								max="500"
								step="1"
							/>
						</label>
					</div>

					<div className="form-control">
						<label>
							Arrow Direction (Margaret Court){" "}
							<Select
								styles={customStyles}
								value={this.state.arrowDirection}
								onChange={this.handleArrowChange}
								options={arrowDirectionOptions}
								className="select"
								name="arrowDirection"
							/>
						</label>
					</div>

					<button type="submit">Render</button>
				</form>
			</div>
		);
	}
}

export default ConfigurationScreen;
